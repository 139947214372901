
import { defineComponent, onMounted, computed, ref, reactive } from 'vue'

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Policy from '@/core/services/Callable/Policy';
import router from '@/router/clean';
import { store } from '@/store';
import Documents from '@/core/services/Callable/Documents';
import Validations from '@/core/services/etc/Validations';
import { FormInstance } from 'element-plus';
import { notification, rule } from '@/store/stateless';
import { updatePolicyDetailsProp } from "@/core/utils/policy.js";
import { ElLoading } from 'element-plus'

export default defineComponent({
    name: 'policy-edit',

    setup() {

        const policyRuleFormRef = ref<FormInstance>()

        const policy = computed(() => {
            return store.state.policy.policy
        })

        const documents = computed(() => {
            return store.getters.getAllDocuments(2)
        })

        const email_activity = computed(() => {
            return store.state.policy.email_activity;
        });

        const disablePolicySaveButton = ref(false)
        const disablePolicyDraftSaveButton = ref(false)
        const emiratesErrorModal = ref(false)
    
        onMounted(async () => {
            const users = window.localStorage.getItem("user");
            const user = users ? JSON.parse(users || "") : "";
            const pUploadPolicy = [1,4,14].includes(user.role_id)
            if(!pUploadPolicy) window.location.href = '/#/404'

            Validations.is_group = false

            const elLoading = ElLoading.service({
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.1)',
            })
            rule.customerDetails = 'insured_person'
            setCurrentPageBreadcrumbs("Edit Policy Details", [{ label: "Policy", value: '' }]);
            await policyDetails()

            // if(!policy.value.insurance_payment)
            // policy.value.insurance_payment = store.getters.getInsurancePayment(policy.value.main_invoice.payment_type)


            if(policy.value.entity_type == null)
                policy.value.entity_type = 2; // default individual for grouop

            policyRef.value.focus()

            elLoading.close()
        })

        const policyDetails = async () => {
            const policy_purchase_id = parseInt(router.currentRoute.value.params.policy_id.toString())
            const payload = { policy_purchase_id: policy_purchase_id }
            await Policy.getPolicy(payload)
            await Policy.getEditPolicySetting()
            await fetchDocument()
            await fetchAmendDocument()
            await Policy.getEmailActivity({policy_id : policy_purchase_id})
        }
        
        const passporrt_validate = (rule: any, value: any, callback: any) => {
            if (policy.value.emirates_id == '111-1111-1111111-1' && !value) {
                callback(new Error('Please enter passport'))
            }  else {
                callback()
            }
        }

        const rules = reactive({
            insured_person: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
            policy_holder: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
            emirates_id: [{ validator: Validations.emirates_id, trigger: ['blur','change','input'] }],
            policy_holder_emirates_id: [{ validator: Validations.emirates_id, trigger: ['blur','change','input'] }],
            policy_number: [{ validator: Validations.policy_number, trigger: ['blur','change','input'] }],
            policy_start_date: [{ validator: Validations.policy_start_date, trigger: ['blur','change','input'] }],
            policy_end_date: [{ validator: Validations.policy_end_date, trigger: ['blur','change','input'] }],
            insurance_payment: [{ validator: Validations.insurance_payment, trigger: ['blur','change','input'] }],
            passport: [{ validator: passporrt_validate, trigger: ['blur','change','input'] }],
            // policy_end_date: [{ validator: Validations.policy_end_date, trigger: ['blur'] }],
            // policy_end_date: [{ validator: Validations.policy_end_date, trigger: ['blur'] }],
        })


        const saveAsDraft = async () => {
            disablePolicyDraftSaveButton.value = true;

            policy.value.documents = documents.value; // appendind documents

            const response = await Policy.updatePolicy(policy.value)

            if(response.status == 200)
            {
                notification.success(
                    'Policy',
                    response.data.message
                );

                if(response.data.data.document_validation.length > 0)
                {
                    setTimeout(() => {
                        notification.warning(
                            'Policy',
                            response.data.data.document_validation,
                        );
                    }, 0);
                }
            }
            
            disablePolicyDraftSaveButton.value = false;

        }

        const isSubmitted = ref(false)
        const policyRef = ref()
        const policyCDRef = ref()
        
        const submitPolicy = async (formEl: FormInstance | undefined) => {

            // console.log(policy.value)
            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                // console.log(valid)
                if (valid) {

                    // if(policy.value.is_amended == 1 && store.getters.getAmendValidation){
                    //     notification.warning(
                    //         'Policy',
                    //         'Please upload amend documents'
                    //     );
                    //     return
                    // }

                    disablePolicySaveButton.value = true;

                    policy.value.documents = documents.value; // appendind documents

                    const response = await Policy.submitPolicy(policy.value)

                    // console.log(response)
                    if(response.status == 200)
                    {
                        notification.success(
                            'Policy',
                            response.data.message
                        );

                        window.scrollTo(0, 0);


                        if(policy.value.status == 4)
                            router.replace({ name: 'completedPolicies' })

                        // await policyDetails()
                        else
                            window.location.reload()

                    }
                    else if(response.response.status == 422){
                        // console.log(response.response.data)
                        const errors = response.response.data.data;
                        let message = '';
                        for(let key in errors)
                        {
                            // console.log(errors[key]);
                            for (const msg of errors[key]) {
                                message += msg + '<br/>';
                            }

                        }
                        
                        notification.warning(
                                response.response.data.message,
                                message
                            );
                    }

                    disablePolicySaveButton.value = false;

                }
                else {
                    rule.customerDetails = Object.keys(fields)[0]
                    if(updatePolicyDetailsProp.includes(rule.customerDetails)) policyRef.value.focus()
                    else policyCDRef.value.focus()
                }
            
                
            })
        }
        

        const user_id = computed(() => {
        // return store.getters.myId;
            let userId = window.localStorage.getItem('uid');
            if(!userId){
                userId = store.getters.myId;
            }
            return userId;
        });

        const fetchDocument = async () => {
            await Documents.getDocuments({
                is_loaded: true,
                categories: ['standard'],
                lead_id: policy.value.lead_id,
                policy_id: policy.value.policy_purchase_id
            })


            // // Get Documents
            // const docs = store.getters.getAllDocuments(2).filter((x) => {
            //     if( x.type == 3 && x.created_by_skye_user && x.created_by_skye_user.id != user_id.value )
            //         return true;

            //     return false;
            // })

            // // console.log(user_id.value, docs )

            // if(docs.length > 0 && !policy.value.emirates_id)
            //     emiratesErrorModal.value = true

        }

        const fetchAmendDocument = async () => {
            // console.log('amends',policy.value)
            await Documents.getAmendDocuments({
                policy_id: policy.value.policy_purchase_id,
            });
        };

        const revalidate = () => {
            // if(isSubmitted.value) {
            //     policyRuleFormRef.value?.validate((valid) => {
            //         if(!valid) {
            //             // 
            //         }
            //     })
            // }
        }

        function revalidateFields(data) {
             if(data) 
                policyRuleFormRef.value?.clearValidate(data)
        }

        const enableAllFields = computed(() => {
            const user  = JSON.parse(window.localStorage.getItem('user') || "")
            
            if(user && policy.value && user.role_id == 14 && policy.value.status == 4) return true

            return false
        })
        return {
            revalidateFields,
            enableAllFields,
            policy,
            rules,
            disablePolicySaveButton,
            disablePolicyDraftSaveButton,
            saveAsDraft,
            submitPolicy,
            policyRuleFormRef,
            revalidate,
            store,
            email_activity,
            policyRef,
            policyCDRef,
            emiratesErrorModal

        }
    },
})
